import { Component, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereDataGridFilterType, CompiereDataGridType, DataStore, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { ProcessPingInfo } from '@compiere-ws/models/process-ping-info';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { SpecificGridQuery, SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { IupicsData } from '@iupics-manager/models/iupics-data';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'iu-account-editor-window-ui',
  templateUrl: './account-editor-window-ui.component.html',
  styleUrls: ['./account-editor-window-ui.component.scss']
})
export class AccountEditorWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('left', { read: ViewContainerRef, static: true })
  vcrLeft: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true })
  vcrMiddle: ViewContainerRef;
  @ViewChild('right', { read: ViewContainerRef, static: true })
  vcrRight: ViewContainerRef;
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  @Output()
  vcrBot: ViewContainerRef;
  @ViewChild('hidden', { read: ViewContainerRef, static: true })
  vcrHidden: ViewContainerRef;
  verifAlias = false;
  verifAcc = false;
  checked = false;
  checkedNode = false;
  combination: string;
  offset: number;
  limit: number;
  hideColumns: string[] = [];
  displayColumns: string[] = [];
  firstRowSelected: number;
  accountIndex: number;
  orgIndex: number;
  lastRowSelected: any;
  hasAlias: string;
  processId = 0;

  private progressSubAccount: Subscription;

  constructor(
    windowFactory: WindowFactoryService,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    private po: PoService,
    progressService: ProcessInProgressService
  ) {
    super(windowFactory, uiCreator, store, processService, socketService, connectorService, progressService, translateService);
    this.isModal = false;
  }

  ngOnInit() {
    if (this.parentComponent instanceof EditViewUiComponent) {
      if (!this.dataStore) {
        this.dataStore = this.store.newSpecificWindowData(
          this.formId,
          (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
        );
      }
    }

    let acctSchemaId =
      this.dataStore.data.C_AcctSchema_ID !== undefined && this.dataStore.data.C_AcctSchema_ID !== null
        ? this.dataStore.data.C_AcctSchema_ID.id
        : null;
    if (acctSchemaId === undefined || acctSchemaId === null) {
      const currentContext = this.getCurrentContext();
      if (currentContext.C_AcctSchema_ID !== undefined && currentContext.C_AcctSchema_ID !== null) {
        acctSchemaId = currentContext.C_AcctSchema_ID.id ? currentContext.C_AcctSchema_ID.id : currentContext.C_AcctSchema_ID;
      } else if (currentContext.$C_AcctSchema_ID !== undefined && currentContext.$C_AcctSchema_ID !== null) {
        acctSchemaId = currentContext.$C_AcctSchema_ID.id ? currentContext.$C_AcctSchema_ID.id : currentContext.$C_AcctSchema_ID;
      }
    }
    if (acctSchemaId !== undefined && acctSchemaId !== null) {
      this.subscriptions.push(
        this.uiCreator
          .getDBSelect(
            "SELECT ELEMENTTYPE,SEQNO, COLUMNNAME FROM C_AcctSchema_Element WHERE C_AcctSchema_ID=? AND IsActive= 'Y' ORDER BY SeqNo ",
            [acctSchemaId],
            []
          )
          .subscribe((response) => {
            if (response !== undefined && !this.verifAcc) {
              this.subscriptions.push(
                this.uiCreator
                  .getDBSelect('SELECT HasAlias FROM  C_AcctSchema WHERE C_AcctSchema_ID=?', [acctSchemaId], [])
                  .subscribe((data) => {
                    let i = 0;
                    let j = 0;
                    const vcr = 'vcrTop';
                    if (data !== undefined && !this.verifAlias) {
                      this.hasAlias = data[0].HASALIAS;
                      if (data[0].HASALIAS === 'Y') {
                        this.customDesignArray.push({
                          vcr: vcr,
                          type: CustomDesignItemType.FIELD,
                          columnName: 'Alias',
                          cssClass: 'p-col-12 p-md-4'
                        });
                        this.displayColumns.push('Alias');
                        j++;
                      } else {
                        this.customDesignArray.push({
                          vcr: 'vcrHidden',
                          type: CustomDesignItemType.FIELD,
                          columnName: 'Alias'
                        });
                        this.hideColumns.push('Alias');
                      }
                      this.verifAlias = true;
                    }
                    this.customDesignArray.push({
                      vcr: vcr,
                      type: CustomDesignItemType.FIELD,
                      columnName: 'Combination',
                      cssClass: 'p-col-12 p-md-4'
                    });
                    this.displayColumns.push('Combination');
                    j++;
                    for (let i = 0; i < response.length; i++) {
                     if (response[i] !== undefined) {             
                        // vcr = this.addVcr(i + j + 1);
                        this.customDesignArray.push({
                          vcr: vcr,
                          type: CustomDesignItemType.FIELD,
                          columnName: response[i].COLUMNNAME,
                          cssClass: 'p-col-12 p-md-4'
                        });
                        this.displayColumns.push(response[i].COLUMNNAME);
                        if (response[i].ELEMENTTYPE === 'OO') {
                          this.orgIndex = i + j;
                        } 
                        if (response[i].ELEMENTTYPE === 'AC') {
                          this.accountIndex = i + j;
                        }
                     }
                    }
                    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
                       specificWindow.items.forEach((item) => {
                          if (item !== undefined) {
	                       if(item.data.columnName != 'CreateNewCombination' && item.data.columnName != 'AccountEditorWindow_table' && item.data.columnName != 'Combination' && item.data.columnName != 'Alias'){
	                        if(!this.displayColumns.includes(item.data.columnName)){
	                          this.customDesignArray.push({
                               vcr: 'vcrHidden',
                               type: CustomDesignItemType.FIELD,
                               columnName: item.data.columnName
                               });
                               this.hideColumns.push(item.data.columnName);
                             }
	                       }
                          }
	                   });
                    });
                    this.customDesignArray.push({
                      vcr: 'vcrLeft',
                      type: CustomDesignItemType.FIELD,
                      columnName: 'CreateNewCombination',
                      cssClass: 'p-col-12'
                    });
                    this.customDesignArray.push({
                      vcr: 'vcrMiddle',
                      type: CustomDesignItemType.FIELD,
                      columnName: 'Search',
                      cssClass: 'p-col-12'
                    });
                    /*this.customDesignArray.push({
                      vcr: 'vcrRight',
                      type: CustomDesignItemType.FIELD,
                      columnName: 'ResetFields',
                      cssClass: 'p-col-12'
                    });*/
                    if (this.parentComponent instanceof EditViewUiComponent) {
                      if (
                        (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data[
                          this.parentComponent.container.accountEditor_ColumnSearch
                        ] !== undefined &&
                        (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data[
                          this.parentComponent.container.accountEditor_ColumnSearch
                        ] !== null
                      ) {
                        this.combination = (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data[
                          this.parentComponent.container.accountEditor_ColumnSearch
                        ].id;
                      }
                      this.customDesignArray.push({
                        vcr: 'vcrBot',
                        type: CustomDesignItemType.GRID,
                        tableName: 'AccountEditorWindow_table',
                        cssClass: 'p-col-12',
                        shouldCreateQuery: true,
                        shouldSelectFirst: true,
                        rowSelection: 'single',
                        hiddenColumns: this.hideColumns
                      });
                      let recordId;
                      if (this.combination !== undefined && this.combination !== null) {
                        recordId = `C_ValidCombination_ID,${this.combination}`;
                      }
                      this.synchronizeData(recordId, true);
                    }
                  })
              );
              this.verifAcc = true;
            }
          })
      );
    }
  }

  /*addVcr(i: number) {
    let vcr: string;
    for (let k = 0; k < 5; k++) {
      if (i === 2 + k * 3) {
        vcr = 'vcrMiddle';
      }
      if (i === k * 3 && i !== 0) {
        vcr = 'vcrRight';
      }
      if (i === 4 + k * 3) {
        vcr = 'vcrLeft';
      }
    }
    return vcr;
  }*/

  selectComination() {
    // permet d'annuler le refresh de l'editViewParent à la fermeture de la fenetre
    if (<EditViewUiComponent>this.parentComponent) {
      this.parentComponent.isProcessLaunch = false;
    }
    if (this.gridViews && this.gridViews[0] && this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows().length > 0) {
      this.dataStore.data.selections[0].selection = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows();
      const sourceFieldName = this.sourceComponent.data.columnName;
      if (sourceFieldName) {
        const event = {
          data: { Data_UUID: sourceFieldName + ',' + this.dataStore.data.selections[0].selection[0].C_ValidCombination_ID }
        };
        (<AutocompleteUiComponent>this.sourceComponent).setSearchSelectItem(event);
      }
    } else {
      this.messageManager.newMessage(
        new IupicsMessage(this.translateService.instant('generic.warning'), 'Veuillez sélectionner une ligne pour remplir la zone de combinaison', 'warning')
      );
    }
   
  }
  synchronizeData(ValidCombinationId: string, start = false) {
    if (ValidCombinationId) {
      const adWindowId = 153;
      const adTabId = 207;

      const request: DataStoreRequest = {
        windowId: adWindowId,
        record_id: ValidCombinationId,
        parent_constraint: null,
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: adTabId,
          startRow: 0,
          endRow: 1
        }
      };
      this.subscriptions.push(
        this.store.getWindowSingleData(request).subscribe((dataWs) => {
          if (dataWs !== undefined) {
            const tabOfColumns = Object.keys(dataWs.data);
            for (let i = 0; i < this.displayColumns.length; i++) {
              const value: string = this.displayColumns[i].toString();
              if (tabOfColumns.includes(value)) {
                if (typeof dataWs.data[value] === 'string') {
                  if (dataWs.data[value] !== null) {
                    this.dataStore.data[value] = dataWs.data[value];
                  } else {
                    this.dataStore.data[value] = null;
                  }
                }
                if (typeof dataWs.data[value] === 'object') {
                  if (dataWs.data[value] !== undefined && dataWs.data[value] !== null) {
                    if (dataWs.data[value].displayValue !== null) {
                        this.dataStore.data[value] = dataWs.data[value];
                    }
                  } else {
                    this.dataStore.data[value] = null;
                  }
                }
              }
            }
          }

          if (start) {
            this.showSpecificWindow();
          }
          this.setDataContainersValueWithChangedStore();
        })
      );
    } else {
      if (start) {
        this.showSpecificWindow();
      }
    }
  }

  notifyFromRowSelected(rowSelected: any) {
    const LengthOfRowSelected = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows().length;
    if (this.lastRowSelected !== undefined) {
      const ValidCombination = this.lastRowSelected.C_ValidCombination_ID;

      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.forEachNode(function (node) {
        if (LengthOfRowSelected === 0) {
          if (node.data && node.data.C_ValidCombination_ID === ValidCombination) {
            node.setSelected(true);
          }
        }
      });
    } else {
      const rowNode = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getDisplayedRowAtIndex(0);
      if (rowNode) {
        this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.forEachNode(function (node) {
          if (LengthOfRowSelected === 0) {
            if (node.data.C_ValidCombination_ID === rowNode.data.C_ValidCombination_ID) {
              node.setSelected(true);
            }
          }
        });
        this.lastRowSelected = rowNode.data;
        this.dataStore.data.selections[0].selection = rowNode.data;
      }
    }

    if (rowSelected.length === 1) {
      this.firstRowSelected = rowSelected[0].C_ValidCombination_ID;
      const ValidCombinationId = rowSelected[0].Data_UUID;
      this.lastRowSelected = rowSelected[0];
      this.synchronizeData(ValidCombinationId);
    }
    if (rowSelected.length > 1) {
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.forEachNode((node) => {
        for (let i = 0; i < rowSelected.length; i++) {
          if (rowSelected[i] !== undefined) {
            if (
              node.data.C_ValidCombination_ID === rowSelected[i].C_ValidCombination_ID &&
              node.data.C_ValidCombination_ID !== this.firstRowSelected
            ) {
              this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.deselectAll();
              this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api
                .getDisplayedRowAtIndex(node.rowIndex)
                .setSelected(true);
              const selectedSingleRow = rowSelected[i];
              const ValidCombinationId = rowSelected[i].Data_UUID;
              this.firstRowSelected = rowSelected[i].C_ValidCombination_ID;
              this.synchronizeData(ValidCombinationId);
              this.dataStore.data.selections[0].selection.pop();
              this.dataStore.data.selections[0].selection = selectedSingleRow;
              this.lastRowSelected = selectedSingleRow;
            }
          }
        }
      });
    }
  }
  onClickResetFields() {
    for (let i = 0; i < this.displayColumns.length; i++) {
      const value: string = this.displayColumns[i].toString();
        this.dataStore.data[value] = null;  
      }
      this.setDataContainersValueWithChangedStore();
  }
  checkBeforeProcessing() {
        if (this.processId === 0) {
          const { processId } = this.fields.find(({ data }) => data.columnName === 'CreateNewCombination');
          if (processId) {
            this.processId = processId;
          }
        }
        if (this.dataStore.data.AD_Org_ID === null || this.dataStore.data.Account_ID === null) {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('specificWindow.fillMandatory'),
              this.fields[this.orgIndex].data.label + ' , ' + this.fields[this.accountIndex].data.label,
              'error'
            )
          );
        } else if (this.hasAlias === 'Y' && this.dataStore.data.Alias) {
          const duplicateAlias =
            "(SELECT COUNT(*) AS COUNT FROM C_ValidCombination WHERE Alias = '" +
            this.dataStore.data.Alias +
            "' AND AD_Client_ID = " +
            this.dataStore.data.AD_Client_ID.id +
            ') cpt';
    
          const request: DataStoreRequest = {
            windowId: null,
            parent_constraint: '',
            compiereRequest: {
              startRow: 0,
              endRow: 0,
              tableName: duplicateAlias,
              filterModel: {}
            }
          };
    
          this.subscriptions.push(
            this.store.getDataGrid(request, true).subscribe((response) => {
              if (response && response.data[0]) {
                if (parseInt(response.data[0]['COUNT'], 10) > 0) {
                  this.messageManager.newMessage(
                    new IupicsMessage(this.translateService.instant('specificWindow.accountEditor.duplicateAlias'), 'error')
                  );
                } else {
                  this.dataStore.data.Combination = null;
                  this.executeProcess(this.processId);
                }
              }
            })
          );
        } else {
          this.dataStore.data.Combination = null;
          return true;
        } 
  }
  notifyFromDataChange(item?: any) {
    if (item.data.isLaunchSearchGrid) {
      // the "Combination" field is not taken into account when modifying another field
      if (item.data.columnName === 'Combination') {
        this.removeExcludedFiltersToGrid('AccountEditorWindow_table');
      } else {
        this.addExcludedFiltersToGrid('AccountEditorWindow_table', 'Combination');
      }
      this.refreshGrids(this.dataStore, false, item.data);
    }
  }

  doItAfterLaunchingOfProcess(processId: number) {
    if (this.parentComponent !== undefined) {
      (this.parentComponent as EditViewUiComponent).isProcessLaunch = true;
    }
    if (this.progressSubAccount) {
      this.progressSubAccount.unsubscribe();
    }
    this.updateLoading(true);
    this.progressSubAccount = this.progressService.watchProcessInProgress().subscribe((pings) => {
      const me = this.connectorService.getIupicsUserAccount();
      const ping = pings.find((p) => {
        return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === processId;
      });
      if (ping && ping.Status === 'finish') {
        this.socketService.closeDataChannel(this.channel_id);
        this.progressSubAccount.unsubscribe();
        this.updateLoading(false);
        this.onEndProcess(ping);
      } 
    });
    (async () => {
      for await (const log of this.socketService.openDataChannel<any>(this.channel_id)) {
        if(log.startsWith('XX_COMBINATION:')){
          const valueTmp = log.substr(15);
          this.dataStore.data['Combination'] = valueTmp;
          const dataContainer = this.dataContainers.find(
            (datacontainer) => datacontainer.data.columnName === 'Combination'
          );
          if(dataContainer){
            dataContainer.fieldValue = valueTmp;
          }
        }
      }
    })();
    this.onTriggerProcess();
  }
}

