import { Component, OnInit} from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-address-view',
  templateUrl: './address-view.component.html',
  styleUrls: ['./address-view.component.scss']
})
export class AddressViewComponent extends SpecificWindowUiComponent implements OnInit {

  constructor(
    protected windowFactory: WindowFactoryService,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected progressService: ProcessInProgressService,
    protected translateService: TranslateService,
    protected messageManager: MessageManagerService
  ) {
    super(windowFactory, uiCreator, store, processService, socketService, connectorService, progressService, translateService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
        specificWindow.items.forEach((item) => {
          switch (item.data.columnName) {
            case 'Address of locals - Table':
              this.customDesignArray.push({
                vcr: 'vcr',
                type: CustomDesignItemType.GRID,
                tableName: item.name,
                cssClass: item.cssClass
              });
              break;
            default:
              if (item.component === 'GridViewUiComponent') {
                this.customDesignArray.push({
                  vcr: 'vcr',
                  type: CustomDesignItemType.GRID,
                  tableName: item.name,
                  cssClass: item.cssClass
                });
              } else if (item.component === 'ButtonUiComponent') {
                this.customDesignArray.push({
                  vcr: 'vcr',
                  type: CustomDesignItemType.FIELD,
                  columnName: item.data.columnName,
                  cssClass: 'ui-g-12 ui-md-4 ui-lg-2'
                });
              } else {
                let vcr = 'vcr';
                if (item.data.IsOptional) {
                  vcr = 'vcrOptional';
                }
                this.customDesignArray.push({
                  vcr: vcr,
                  type: CustomDesignItemType.FIELD,
                  columnName: item.data.columnName,
                  cssClass: 'ui-g-12 ui-md-4 ui-lg-2'
                });
              }
              break;
          }
        });
        this.showSpecificWindow();
      })
    );
  }
}
