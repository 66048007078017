import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppConfig } from '@iupics-config/app.config';
import { IupicsCookieService, LocalStorageIupics } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { Utils } from '@iupics-util/tools/util';
import { LanguageSelectionService } from '@web-desktop/components/menu-top/controllers/language-selection/language-selection.service';
import { environment } from 'environments/environment';
import jwt_decode from 'jwt-decode';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccesPortalGuard extends KeycloakAuthGuard {
  isContextInitialized = false;

  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private connectorService: SecurityManagerService,
    private cookieService: IupicsCookieService,
    private languageSelectionService: LanguageSelectionService,
    private config: AppConfig
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url
      });
    }

    if (!this.isContextInitialized) {
      Utils.displayLoadingScreen();
      const token = await this.keycloak.getToken();
      const profile = await this.keycloak.loadUserProfile();
      const decodedToken = jwt_decode(token);
      let language = environment.default_language;
      if (decodedToken['session'] && decodedToken['session']['webSession']) {
        language = decodedToken['session']['webSession'];
      }
      // Generate Crypto Key
      this.generateCryptoKey();
      // Generate Device ID
      this.generateDeviceId();
      // Init Application language and select prefered language
      this.initLanguages(language);
      await firstValueFrom(this.connectorService.initContext(profile));
      this.isContextInitialized = true;
      Utils.hideLoadingScreen();
      return this.checkAccess(route);
    } else {
      return this.checkAccess(route);
    }
  }

  checkAccess(route: ActivatedRouteSnapshot) {
    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }

  generateCryptoKey() {
    if (!this.cookieService.checkLS(LocalStorageIupics.crypto_key)) {
      this.cookieService.generateRandomKeyCookie();
    }
  }

  generateDeviceId() {
    if (!this.cookieService.checkLS(LocalStorageIupics.device_id)) {
      this.cookieService.setAndEncryptLocalStorage(LocalStorageIupics.device_id, Global.device);
    }
  }

  initLanguages(language: string) {
    this.languageSelectionService.initLanguages(language);
  }
}
