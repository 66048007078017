import { Injectable } from '@angular/core';
import { DocumentNotposted } from '@compiere-ws/models/document-notposted';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable()
export class DocumentNotpostedService {
  private url: string;

  constructor(private config: AppConfig, private http: ApiService) {
    this.url = this.config.getBackendResource('documentNotposted');
  }

  getDocumentNotPosted(): Observable<DocumentNotposted[]> {
    return this.http.get<DocumentNotposted[]>(this.url);
  }

}
