import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IupicsComponentsModule } from '@iupics-components/iupics-components.module';
import { PrimengModule } from '@iupics-components/primeng.module';
import { ChartWidgetComponent } from './components/chart-widget/chart-widget.component';
import { GridWidgetComponent } from './components/grid-widget/grid-widget.component';
import { ListWidgetComponent } from './components/list-widget/list-widget.component';
import { RecentItemWidgetUiComponent } from './components/recent-item-widget-ui/recent-item-widget-ui.component';
import { DocumentNotpostedWidgetUiComponent } from './components/document-notposted-widget-ui/document-notposted-widget-ui.component';

@NgModule({
  declarations: [ChartWidgetComponent, ListWidgetComponent, GridWidgetComponent, RecentItemWidgetUiComponent,DocumentNotpostedWidgetUiComponent ],
  imports: [CommonModule, PrimengModule, IupicsComponentsModule],
  exports: [ChartWidgetComponent, ListWidgetComponent, GridWidgetComponent, RecentItemWidgetUiComponent,DocumentNotpostedWidgetUiComponent]
})
export class IupicsWidgetsModule {}
