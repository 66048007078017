<span
  class="inputSwitchlabel"
  [style.display]="displayCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipPosition="top"
  [showDelay]="500"
  tooltipZIndex="2100"
>
  <ng-container *ngIf="isTriState">
    <p-triStateCheckbox
      (contextmenu)="onContextMenu($event)"
      (press)="onContextMenu($any($event))"
      [(ngModel)]="checked"
      [disabled]="isReadOnly"
      [ngClass]="[hasConflict ? 'iu-field-conflict' : '', 'iu-tri-state-checkbox']"
      [style.backgroundColor]="backgroundColorCss"
      data-cy="input-switch-tri"
      [attr.data-cy-columnName]="data ? data.columnName : columnName"
      [attr.data-cy-value]="checked"
      checkboxTrueIcon="icon-checkbox-checked"
      checkboxFalseIcon="icon-checkbox-tri-false"
    ></p-triStateCheckbox>
  </ng-container>
  <ng-container *ngIf="!isTriState">
    <p-checkbox
      (contextmenu)="onContextMenu($event)"
      (press)="onContextMenu($any($event))"
      [(ngModel)]="checked"
      [binary]="true"
      [disabled]="isReadOnly"
      [ngClass]="[hasConflict ? 'iu-field-conflict' : '', 'iu-checkbox']"
      [style.backgroundColor]="backgroundColorCss"
      data-cy="input-switch"
      [attr.data-cy-columnName]="data ? data.columnName : columnName"
      [attr.data-cy-value]="checked"
    ></p-checkbox>
  </ng-container>
  <span
    *ngIf="this.isLabelDisplay"
    style="height: 100%"
    (contextmenu)="onContextMenu($event)"
    (press)="onContextMenu($any($event))"
    [ngClass]="[hasConflict ? 'iu-field-conflict-icon' : '']"
    #spanRef
  >
    <p style="width: fit-content" (click)="!isReadOnly && labelClick($event)">{{ label }}</p>
    <i
      style="float: none"
      *ngIf="hasConflict && dataStored"
      #spanInput
      class="rtconflict fas fa-exclamation-triangle iconClass iu-field-conflict-icon"
      (click)="showConflictPanel($event)"
      aria-hidden="true"
    >
    </i>
  </span>
  <iu-prime-overlay #opValuePref *ngIf="showValuePrefPanel" [dismissable]="false" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"
    ></iu-value-preference-panel>
  </iu-prime-overlay>
</span>

<iu-prime-overlay #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data?.columnName], true); opConflict.hide()">{{
    (dataStored?.data)[data?.columnName]
  }}</a>
  | <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide()">{{ conflictedData }}</a>
</iu-prime-overlay>
