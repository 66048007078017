<!-- #region body -->
<p-progressBar
  *ngIf="isLoading"
  [mode]="'indeterminate'"
  [value]="0"
  [showValue]="false"
  [styleClass]="'loading-progressbar'"
></p-progressBar>
<div class="iu-export-data-body">
  <ng-container *ngIf="exportDataOptions$ | async as edo; else loading">
    <!-- #region footer -->
    <div class="iu-export-data-footer">
      <div class="p-col-12 p-md-6">
        <iu-autocomplete-ui
          [isContextMenuDisabled]="true"
          #printFormatsAutocomplete
          [cssClass]="''"
          tooltip="{{ 'exportData.printFormats' | translate }}"
          label="{{ 'exportData.printFormats' | translate }}"
          [fieldValue]="this.defaultPrintFormat ? this.defaultPrintFormat : ''"
          (fieldValueModified)="setPrintFormat($any($event))"
          [isStandalone]="true"
          [data]="edo.printFormats"
        ></iu-autocomplete-ui>
      </div>
      <div class="p-col-12 p-md-6">
        <iu-autocomplete-ui
          [isContextMenuDisabled]="true"
          #fileTypesAutocomplete
          [cssClass]="''"
          tooltip="{{ 'exportData.fileTypes' | translate }}"
          label="{{ 'exportData.fileTypes' | translate }}"
          [fieldValue]="this.defaultFileFormat ? this.defaultFileFormat : ''"
          (autoCompleteEmitter)="setFileType($any($event))"
          [isStandalone]="true"
          [data]="edo.fileTypes"
        ></iu-autocomplete-ui>
      </div>
    </div>
    <div class="iu-export-data-footer-buttons">
      <button
        pButton
        class="p-button p-button-alert"
        (click)="cancelExportDataEmitter.emit()"
        [label]="'exportData.cancel' | translate"
      ></button>
      <button
        pButton
        class="p-button p-button-primary"
        (click)="exportData($event, edo.recordIds)"
        [label]="'exportData.exportData' | translate"
      ></button>
    </div>
  </ng-container>
</div>
<!-- #endregion -->

<ng-template #loading>
  <div class="iu-export-data-footer">
    <div class="p-col-12 p-md-6">
      <iu-autocomplete-ui
        #printFormatsAutocomplete
        tooltip="{{ 'exportData.printFormats' | translate }}"
        label="{{ 'exportData.printFormats' | translate }}"
        [data]="{ items: [] }"
      ></iu-autocomplete-ui>
    </div>
    <div class="p-col-12 p-md-6">
      <iu-autocomplete-ui
        #fileTypesAutocomplete
        tooltip="{{ 'exportData.fileTypes' | translate }}"
        label="{{ 'exportData.fileTypes' | translate }}"
        [data]="{ items: [] }"
      ></iu-autocomplete-ui>
    </div>
  </div>
  <div class="iu-export-data-footer-buttons">
    <button pButton class="p-button p-button-alert" [label]="'exportData.cancel' | translate"></button>
    <button pButton class="p-button p-button-primary" [label]="'exportData.exportData' | translate"></button>
  </div>
</ng-template>
