<div #riListElt class="documents-notposted">
    <ng-container *ngIf="documentNotposted$ | async as documentNotposted; riLoading">
      <div *ngFor="let item of documentNotposted" class="document-notposted" (click)="openTab($event, item)">
        {{ item.name }}
      </div>
    </ng-container>
  </div>
  
  <ng-template #riLoading>
    <div class="document-notposted">Loading...</div>
  </ng-template>
  