import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DocumentNotposted } from '@compiere-ws/models/document-notposted';
import { DocumentNotpostedManagerService } from '@iupics-manager/managers/document-notposted-manager/document-notposted-manager.service';
import { IupicsWidget } from '@web-desktop/models/iupics-widget';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'iu-document-notposted-widget-ui',
  templateUrl: './document-notposted-widget-ui.component.html',
  styleUrls: ['./document-notposted-widget-ui.component.scss']
})
export class DocumentNotpostedWidgetUiComponent implements OnInit, AfterViewInit, OnDestroy {
    documentNotposted$: Observable<DocumentNotposted[]>;
  refresherSub: Subscription;

  @Input() widget: IupicsWidget;

  @Output() openTabEmitter = new EventEmitter<any>();

  constructor(private riManager: DocumentNotpostedManagerService) {}

  ngOnInit() {
    this.documentNotposted$ = this.riManager.getDocumentNotPosted();
  }

  ngAfterViewInit() {
    this.refresherSub = this.widget.refresher.subscribe(() => (this.documentNotposted$ = this.riManager.getDocumentNotPosted()));
  }

  openTab(event: Event, item: DocumentNotposted) {
    event.stopPropagation();
    this.openTabEmitter.emit({
      action_id: item.entityID,
      menu_type: 'Window',
      name: item.name.split(':')[0],
      iconClass: 'fa fa-windows',
      openedRecordId: item.entityKey
    });
  }

  ngOnDestroy() {
    this.refresherSub.unsubscribe();
  }
}
