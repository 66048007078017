import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { DocserverEntry } from '@compiere-ws/models/docserver-entry-json';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { ElasticService } from '@elastics-ws/services/elastic.service';
import { PrimeOverlayComponent } from '@iupics-components/overrided/prime-overlay/prime-overlay.component';
import { InputTextUiComponent } from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import { PreviewDocComponent } from '@iupics-components/standard/preview-doc/preview-doc.component';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { MongoSearchQueryCombination } from '@iupics-manager/models/mongo-search';
import { TranslateService } from '@ngx-translate/core';
import { TabSplitViewContentUiComponent } from '@web-desktop/components/workspace/components/tab-split-view-content-ui/tab-split-view-content-ui.component';
import * as mime from 'mime';
import { OverlayPanel } from 'primeng/overlaypanel';
import { of, zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MenuUiManagerService } from '../../controllers/menu-ui-manager/menu-ui-manager.service';
@Component({
  selector: 'iu-usearch-container',
  templateUrl: './usearch-container.component.html',
  styleUrls: ['./usearch-container.component.scss']
})
export class USearchContainerComponent extends AbstractDynamicComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('overlayArea', { static: true })
  overlayArea: ElementRef;
  @ViewChild('opPreviewDoc', { static: true })
  opPreviewDoc: OverlayPanel;
  @ViewChild('inputSearch', { static: true })
  inputSearch: InputTextUiComponent;
  @ViewChild('searchcontainerwindow', { static: true })
  window: TabSplitViewContentUiComponent;
  @ViewChild('previewDocModal')
  private previewDocModalComponent: PreviewDocComponent;
  @ViewChild('previewDoc')
  private previewDocComponent: PreviewDocComponent;

  @Input()
  searchcontainer: TabSplitViewContentUiComponent;
  @Input()
  textValue: any;

  @Output()
  openTargetSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  closeSearch: EventEmitter<boolean> = new EventEmitter<boolean>();

  id: string;
  name: string;
  appName: string;
  componentRef: ComponentRef<any>;
  bounds: HTMLElement;
  inBounds: boolean;
  windowId: number;
  vcrwindow: ViewContainerRef;
  index: number;
  description: string;
  help: string;
  values: any[] = [];
  menuAccess: any;
  count: number;
  timer: any;
  TimeCounter = 0;

  isLoading = false;
  isFirstSearch = true;
  onlyDocFieldValue = 'N';
  topResults: any[];
  totResult: number;
  catsResult: any[];
  resultsByCategory: any[] = [];
  templateRowsCountResultsByCategory = 1;
  cats: any[];
  selectedCategory: string;

  fileName;
  urlFile;
  showPreviewTimeout;
  previewFilePosition: any;
  overridePreviewPanelStyle: string[][];

  bpSmall = '640px';
  bpMedium = '1024px';

  constructor(
    private elasticService: ElasticService,
    public translateService: TranslateService,
    // private config: AppConfig,
    private menuUiManager: MenuUiManagerService,
    private messageManager: MessageManagerService,
    private docServerService: DocServerService // private smService: SecurityManagerService
  ) {
    super();
  }

  ngOnInit() {
    this.selectedCategory = this.translateService.instant('usearch.allResults');
  }

  ngAfterViewInit() {
    setTimeout(() => this.inputSearch.inputRef.nativeElement.focus(), 0);
  }

  search(event) {
    if (event.ctrlKey || event.keyCode === 17) {
      return false;
    } else if (event.keyCode === 13 || event.type === 'click') {
      this.isFirstSearch = false;
      this.topResults = [];
      this.catsResult = [];
      this.totResult = 0;
      this.cats = [];
      if (
        this.inputSearch.fieldValue !== null &&
        this.inputSearch.fieldValue !== undefined &&
        this.inputSearch.fieldValue !== ''
      ) {
        this.isLoading = true;
        const localCat = { cat: { displayValue: this.translateService.instant('usearch.menuPoints'), id: -1 }, sources: [] };
        // on permet à l'utilisateur de faire une recherche comme sur compier * => %
        const researchString: string = this.inputSearch.fieldValue.toLowerCase().replace(/%/g, '.*');
        let researchStringEL: string =
          '(' + this.inputSearch.fieldValue.toLowerCase().replace(/%/g, '*').replace(/ and /g, ' AND ') + ')';
        let re;
        try {
          re = new RegExp(researchString);
        } catch (error) {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('usearch.searchLabel'),
              this.translateService.instant('usearch.searchError'),
              'error'
            )
          );
          this.isLoading = false;
          return false;
        }

        this.menuUiManager.loadMenusList().subscribe((menus) => {
          const searchResults = [];
          if (this.onlyDocFieldValue === 'N') {
            menus.forEach((menu) => {
              // rajoute les points de menu mathcant la regex

              if (re.test(menu.name.toLowerCase()) === true) {
                localCat.sources.push({
                  displayValue: menu.name,
                  id: menu.action_id,
                  menu_type: menu.menu_type,
                  iconClass: menu.iconClass
                });
                this.totResult++;
              }
            });
            if (this.totResult > 0) {
              this.cats = [{ label: this.translateService.instant('usearch.allResults'), icon: '' }];
              this.cats.push({ label: this.translateService.instant('usearch.menuPoints'), icon: '' });
              this.catsResult.push(localCat);
            }
            searchResults.push(
              this.elasticService.getWsSearch(researchStringEL).pipe(
                catchError((error) => {
                  this.messageManager.newMessage(
                    new IupicsMessage(
                      this.translateService.instant('usearch.searchLabel'),
                      this.translateService.instant('usearch.noService'),
                      'error'
                    )
                  );
                  return of(null);
                })
              )
            );
          } else {
            searchResults.push(of(null));
          }
          const arrayString = this.inputSearch.fieldValue.toLowerCase().replace(/%/g, '*').split(' ');
          researchStringEL = '';
          arrayString.forEach((text) => {
            researchStringEL += `*${text}*`;
          });
          const searchQuery = this.docServerService.createQuery(
            [
              { 'META|OCR_DATA': researchStringEL.replace(/\*/g, '.*') },
              { 'META|SUMMARY': researchStringEL.replace(/\*/g, '.*') },
              { 'META|FILENAME': researchStringEL.replace(/\*/g, '.*') }
            ],
            MongoSearchQueryCombination.OR,
            true
          );
          searchResults.push(
            this.docServerService
              .advancedSearchDocuments(searchQuery, { limit: -1, start: 0, attachmentInteraction: false })
              .pipe(
                catchError((error) => {
                  if (this.onlyDocFieldValue === 'Y') {
                    this.messageManager.newMessage(
                      new IupicsMessage(
                        this.translateService.instant('usearch.searchLabel'),
                        this.translateService.instant('usearch.noDocServerService'),
                        'error'
                      )
                    );
                  }
                  return of([]);
                })
              )
          );
          const searchResultZip = zip(...searchResults);
          this.subscriptions.push(
            searchResultZip.subscribe({
              next: ([response1, response2]: any[]) => {
                if (response1 && response1.total > 0) {
                  if (this.totResult === 0) {
                    this.cats = [{ label: this.translateService.instant('usearch.allResults'), icon: '' }];
                  }
                  this.topResults = [...this.topResults, ...response1.top];
                  this.catsResult = [...this.catsResult, ...response1.cats];
                  this.totResult += response1.total;
                  response1.cats.forEach((cat) => {
                    this.cats.push({ label: cat.cat.displayValue, icon: '' });
                  });
                }
                if (response2 && response2.entries && response2.entries.length > 0) {
                  const fileCategory = {
                    cat: {
                      displayValue: this.translateService.instant('usearch.fileCatLabel'),
                      id: -999
                    },
                    sources: []
                  };
                  if (this.totResult === 0 && (!response1 || response1.total <= 0)) {
                    this.cats = [{ label: this.translateService.instant('usearch.allResults'), icon: '' }];
                  }
                  this.cats.splice(1, 0, { label: fileCategory.cat.displayValue, icon: '' });
                  response2.entries.forEach((hit: DocserverEntry) => {
                    fileCategory.sources.push({
                      src: hit.url,
                      name: hit.fileName,
                      id: hit.id,
                      docId: hit.docId,
                      attachment_ID: hit.attachment_ID,
                      displayValue: hit.fileName
                    });
                    this.totResult++;
                  });
                  this.catsResult = [...[fileCategory], ...this.catsResult];
                  /* Sets icon for each result */
                  this.catsResult.forEach((cat) => cat.sources.forEach((item) => this.setIcon(item)));
                }
                this.onClick(null);
                this.isLoading = false;
              },
              error: (error) => {
                this.messageManager.newMessage(
                  new IupicsMessage(
                    this.translateService.instant('usearch.searchLabel'),
                    this.translateService.instant('usearch.searchError'),
                    'error'
                  )
                );
                this.isLoading = false;
              }
            })
          );
        });
      }
    }
  }
  private setIcon(item: any) {
    item.isMenu = Object(item).hasOwnProperty('menu_type');

    if (!item.isMenu) {
      item.iconFileClass = 'icon-';
      if (item.displayValue.endsWith('pdf')) {
        item.iconFileClass += 'pdf';
      } else if (item.displayValue.endsWith('csv')) {
        item.iconFileClass += 'csv';
      } else if (item.displayValue.endsWith('txt')) {
        item.iconFileClass += 'txt';
      } else if (item.displayValue.endsWith('xls') || item.displayValue.endsWith('xlsx')) {
        item.iconFileClass += 'excel';
      } else if (item.displayValue.endsWith('doc') || item.displayValue.endsWith('docx')) {
        item.iconFileClass += 'word';
      } else if (item.displayValue.endsWith('sql')) {
        item.iconFileClass += 'sql';
      } else if (item.displayValue.endsWith('zip')) {
        item.iconFileClass += 'zip';
      } else if (item.displayValue.endsWith('html')) {
        item.iconFileClass += 'html';
      } else {
        item.iconFileClass += 'file';
      }
    }
  }

  updateContainerZone(n: number) {}

  onChildUpdate(event: IupicsEvent): void {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  onClick(event: any) {
    this.resultsByCategory = [];
    if (!(this.selectedCategory.toLowerCase() === this.translateService.instant('usearch.allResults').toLowerCase())) {
      this.catsResult.forEach((value) => {
        if (value.cat.displayValue.toLowerCase().includes(this.selectedCategory.toLowerCase())) {
          this.resultsByCategory = value.sources;
          this.resultsByCategory.forEach((item) => (item.cat = value.cat));
          this.setGridRowsCount();
        }
      });
    }
  }

  getResultsListByCategory(category: string): any[] {
    let results = [];
    this.catsResult.forEach((value) => {
      if (value.cat.displayValue.toLowerCase().includes(category.toLowerCase())) {
        results = value.sources;
      }
    });

    return results;
  }

  seeMoreDetails(event: Event, itemCategory: string) {
    event.stopPropagation();
    this.catsResult.forEach((category) => {
      if (category.cat.displayValue.toLowerCase() === itemCategory.toLowerCase()) {
        this.selectedCategory = itemCategory;
        this.resultsByCategory = this.getResultsListByCategory(this.selectedCategory);
        this.resultsByCategory.forEach((item) => (item.cat = category.cat));
      }
    });
    this.setGridRowsCount();
  }

  openTarget(target) {
    this.openTargetSearch.emit(target);
  }
  downloadFile(file) {
    if (file && this.hasPreview(file.name)) {
      this.cancelPreviewFile();
      this.previewDocComponent.onPreview(file.name, file.src);
    } else {
      this.directDownload(file);
    }
  }
  directDownload(file) {
    if (file) {
      const sub = this.docServerService.downloadDocument(file.src as string).subscribe((response) => {
        Global.downloadFile(response, file.name as string);
        sub.unsubscribe();
      });
    }
  }
  hasPreview(name: string) {
    const nameTolower: string = name.toLowerCase();
    let hasPreview = false;
    const ext = nameTolower ? (nameTolower as string).toLocaleLowerCase().split('.').pop() : null;
    if (ext === 'pdf') {
      hasPreview = true;
    }
    if (!hasPreview) {
      const mimeType = mime.getType(ext);
      if (mimeType && mimeType.includes('image')) {
        hasPreview = true;
      }
    }
    return hasPreview;
  }
  previewFile(event, result) {
    if (result) {
      if (!this.hasPreview(result.name)) {
        this.cancelPreviewFile();
      } else {
        const isOpen = this.cancelPreviewFile(false);
        this.alignPreviewFile(event);
        this.opPreviewDoc.align();
        this.fileName = result.name;
        this.urlFile = result.src;
        if (isOpen) {
          if (this.previewDocModalComponent) {
            this.previewDocModalComponent.onPreview(this.fileName, this.urlFile);
          }
        } else {
          if (this.overlayArea.nativeElement) {
            this.showPreviewTimeout = setTimeout(() => {
              this.opPreviewDoc.show(event, this.overlayArea.nativeElement);
            }, 400);
          }
        }
      }
    }
  }
  cancelPreviewFile(shouldHide = true) {
    let isOpen = false;
    if (this.showPreviewTimeout) {
      clearTimeout(this.showPreviewTimeout);
      this.showPreviewTimeout = null;
    }
    if (this.opPreviewDoc && this.opPreviewDoc.overlayVisible) {
      if (shouldHide) {
        this.opPreviewDoc.hide();
      }
      isOpen = true;
    }
    return isOpen;
  }
  alignPreviewFile({ x, y, target }: MouseEvent) {
    /* Preview size */
    const pWidth = 40;
    const pHeight = 48;
    /* Alignement offset */
    const offset = 10;
    /* viewport width */
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    /* Target position */
    const targetBCR = (target as HTMLElement).getBoundingClientRect();
    const left = `${Math.floor(vw / 2) >= x ? targetBCR.left : targetBCR.right - Math.floor(vw * (pWidth / 100))}px`;
    const top = `${Math.floor(vh / 2) >= y ? targetBCR.bottom : targetBCR.top - Math.floor(vh * (pHeight / 100)) - offset}px`;
    /* Override prime overlay panel style  */
    this.overridePreviewPanelStyle = [
      ['position', 'absolute'],
      ['width', `${pWidth}%`],
      ['height', `${pHeight}%`],
      ['top', top],
      ['right', 'auto'],
      ['bottom', 'auto'],
      ['left', left],
      ['z-index', '8000']
    ];
  }
  handleAlign(context: PrimeOverlayComponent) {
    if (context?.overlayVisible) {
      this.overridePreviewPanelStyle.forEach(([key, value]) => (context.container.style[key] = value));
    }
  }

  changeOnlyDocFieldValue() {
    this.onlyDocFieldValue = this.onlyDocFieldValue === 'N' ? 'Y' : 'N';
  }

  emitCloseSearch() {
    this.closeSearch.emit(true);
  }

  setGridRowsCount() {
    const nbCol = window.innerWidth <= 640 ? 1 : window.innerWidth <= 1024 ? 2 : 3;

    this.templateRowsCountResultsByCategory =
      this.resultsByCategory.length > 10 ? Math.ceil(this.resultsByCategory.length / nbCol) : 10;
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setGridRowsCount();
  }
}
