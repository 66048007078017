<li
  [style.width.px]="tabWidth"
  tooltipPosition="top"
  showDelay="250"
  pTooltip="{{ name }}"
  #tab
  [ngClass]="[isActive ? 'active' : '', 'tab']"
  draggable="true"
  id="{{ id }}"
  (drop)="handleDrop($event)"
  (dragstart)="handleDragStart($event)"
  (dragend)="handleDragEnd($event)"
  (dragover)="handleDragOver($event)"
  (click)="setActive($event)"
  (auxclick)="$event?.button === 1 ? handleRemove() : null"
>
  <div class="name" data-cy="tab-name" [attr.data-cy-tabName]="name">
    <span class="tab-icon {{ iconClass }}"></span>{{ isLoading ? ('tabUi.loading' | translate) : name }}
  </div>
  <div
    #removeBtn
    class="remove"
    (click)="handleRemove()"
    iuKeybinding
    iuKeybinding-code="menu-top.close-tab"
    [iuKeybinding-active]="true"
    [iuKeybinding-badge-active]="isActive"
    [iuKeybinding-behavior-function]="handleKeybind"
    [iuKeybinding-behavior-context]="this"
    data-cy="tab-close-icon"
  ></div>
</li>
