<!-- #region favorite -->
<div class="apiz-p-grid" style="position: relative">
  <div
    [ngClass]="[
      isMobile ? 'menu-mobile' : '',
      isMobile && showMenuFavorites ? 'menu-mobile-show' : '',
      'p-col-11 p-md-11 p-lg-11 menu-closed'
    ]"
  >
    <iu-dashboard-group-ui
      data-cy="favorites-groups"
      (changeMenuItemList)="changeMenuItemList($event)"
      (addGroupItem)="addGroupItem($event)"
      (renameGroupItem)="renameGroupItem($event)"
      (removeGroupItem)="removeGroupItem($event)"
      [groups]="groups"
      (dragEndEmitter)="switchMenuGroup($event)"
      keybinding="dashboard.favorite-group"
    ></iu-dashboard-group-ui>
  </div>
  <h2 class="p-col-10 mobileOnly">
    <div class="dashboard-section-header">
      <button
        data-cy="mobile_menu_favorite_toggle"
        class="mobile_menu_bar_toggle"
        iuToggleButton
        [iuToggleButtonSwitch]="true"
        [(iuToggleButtonVar)]="showMenuFavorites"
      >
        <i
          [ngClass]="[showMenuFavorites ? 'icon-menu-bars-toggle-opened' : 'icon-menu-bars-toggle-closed']"
          aria-hidden="true"
        ></i>
      </button>
      <div class="menu_name">{{ groups | dashboardGroupName: currentGroupID }}</div>
    </div>
  </h2>
  <div class="p-col-1 p-md-1 p-lg-1 ui-sd-2 shortManage">
    <ul
      id="shortcutsManager-container"
      class="shortcutsManager"
      iuAddActive
      [iuAddActive-idToCheck]="'shortcutsManager-add'"
      [iuAddActive-otherToClose]="['widgetManager-container']"
      iuKeybinding
      [iuKeybinding-active]="true"
      iuKeybinding-code="dashboard.favorite-menu"
    >
      <li>
        <a
          id="shortcutsManager-add"
          href="#"
          tooltipPosition="left"
          [showDelay]="250"
          [life]="3"
          pTooltip="{{ 'dashboard.addShortcut' | translate }}"
          class="add"
          (click)="openMenuFavorite($event)"
        >
          <i class="icon-add" aria-hidden="true"></i>
        </a>
        <ul>
          <ng-container *ngIf="menuCategories$ | async as menuCategories">
            <li class="title">{{ 'dashboard.availableShortcuts' | translate }}</li>
            <li class="searchSC">
              <input
                #searchInput
                class="searchInput p-inputtext"
                (keydown)="handleInputKeydown($event)"
                (keyup)="handleInputKeyup($event, menuCategories)"
                [(ngModel)]="searchFavoriteValue"
                type="text"
                iuAutofocusDirective
              />
              <i class="iconeSearch icon-search-bolder" aria-hidden="true" #ddBtn type="button"></i>
            </li>
            <span
              id="iu_favorite_container"
              *ngIf="
                !(
                  searchFavoriteValue &&
                  searchFavoriteValue !== '' &&
                  searchFavoriteValue !== '!' &&
                  searchFavoriteValue !== '#' &&
                  searchFavoriteValue !== '!#'
                );
                else filterSearch
              "
              class="listNiv-1"
            >
              <ng-container *ngFor="let menuCategory of menuCategories; let i = index">
                <span *ngIf="selectedCategory === undefined">
                  <li>
                    <a
                      id="iu_favorite_category_{{ menuCategory.category }}"
                      href="#"
                      [ngClass]="[arrowFocusedCategory === menuCategory.category ? 'item-arrow-focused' : '', 'open-icon']"
                      (click)="$event.preventDefault(); openShortCut($event, menuCategory.category)"
                    >
                      <span>{{ menuCategory.category }}</span>
                      <i class="icon-open" aria-hidden="true"></i>
                    </a>
                  </li>
                </span>
                <li *ngIf="selectedCategory === menuCategory.category" class="opened">
                  <a href="#" class="back-icon" (click)="$event.preventDefault(); closeShortCut($event)">
                    <i class="icon-back" aria-hidden="true"></i>
                    <span>{{ menuCategory.category }}</span>
                  </a>
                  <ul>
                    <li *ngFor="let menu of menuCategory.items">
                      <a
                        id="iu_favorite_menu_{{ menu.menu_id }}"
                        href="#"
                        pTooltip="{{
                          menu.menu_category ? menu.menu_category.name + '/' : ('dashboard.menu.others' | translate) + '/'
                        }}"
                        tooltipPosition="bottom"
                        [ngClass]="[arrowFocusedMenu?.menu_id === menu.menu_id ? 'item-arrow-focused' : '', 'add-icon']"
                        (click)="$event.preventDefault(); addFavorite(menu)"
                      >
                        <span class="favorite-icon {{ menu.iconClass }}" aria-hidden="true"></span>
                        <span>{{ menu.name }}</span>
                        <i class="icon-add" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </li>
              </ng-container>
            </span>
            <ng-template #filterSearch>
              <span id="iu_favorite_container" class="filtredList">
                <li *ngFor="let menuCategory of menuCategories | favoriteFilter: searchFavoriteValue">
                  <a
                    id="iu_favorite_menu_{{ menuCategory.menu_id }}"
                    href="#"
                    pTooltip="{{
                      menuCategory.menu_category
                        ? menuCategory.menu_category.name + '/'
                        : ('dashboard.menu.others' | translate) + '/'
                    }}"
                    tooltipPosition="bottom"
                    [ngClass]="[arrowFocusedMenu?.menu_id === menuCategory.menu_id ? 'item-arrow-focused' : '', 'add-icon']"
                    (click)="$event.preventDefault(); addFavorite(menuCategory)"
                  >
                    <span class="favorite-icon {{ menuCategory.iconClass }}"></span> {{ menuCategory.name }}</a
                  >
                </li>
              </span>
            </ng-template>
          </ng-container>
        </ul>
      </li>
    </ul>
  </div>
</div>
<div class="apiz-p-grid shortcutsList" data-cy="favorites">
  <ng-container *ngIf="currentItemList$ | async as currentItemList; else favoriteLoading">
    <ng-container *ngFor="let favorite of currentItemList">
      <div
        #dragElem
        class="drag-item p-col-12 p-md-6 p-lg-3"
        (dragstart)="handleDragStart($event, dragElem, favorite)"
        (mouseover)="isDragMode && dragElem !== activeDiv ? handleMouseOver($event, dragElem) : null"
      >
        <iu-favorites-ui
          [favorite]="favorite"
          (deleteFavorite)="onDelete($event)"
          (openFavorite)="openTab($event)"
          (updateFavorite)="updateFavorite($event)"
        ></iu-favorites-ui>
      </div>
    </ng-container>
  </ng-container>
</div>
<!-- #endregion -->

<ng-template #favoriteLoading> <!-- on peut mettre le loader ici --> </ng-template>
