import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { environment } from 'environments/environment';
import * as mime from 'mime';
import { from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'iu-preview-doc',
  templateUrl: './preview-doc.component.html',
  styleUrls: ['./preview-doc.component.scss']
})
export class PreviewDocComponent implements OnInit, AfterViewInit {
  @Input()
  modal = true;
  @Input()
  urlFile;
  @Input()
  fileName;
  isPreviewDisplayed = false;
  isImagePreview = false;
  isDocPreview = false;
  @Input()
  url: SafeUrl;
  blob: any;
  extension: string;
  @Input()
  z_index = 0;
  @Input()
  modalStyle = null;
  @Input()
  imgStyle = null;
  mediaType = 'application/pdf';
  showError = false;
  @Input()
  fromDocServer = true;
  hasPreviewError = false;
  constructor(
    private docServerService: DocServerService,
    private connectorService: SecurityManagerService,
    private sanitized: DomSanitizer,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    let builedStyle = {
      'iu-modal-body': { 'align-items': 'center', height: document.documentElement.clientHeight / 1.5 + 'px' }
    };
    if (this.z_index > 0) {
      builedStyle['iu-modal-background'] = {
        'z-index': this.z_index,
        height: '100%',
        top: '0'
      };
    }
    this.modalStyle = builedStyle;
  }
  ngAfterViewInit(): void {
    if (this.fileName && this.urlFile) {
      this.onPreview(this.fileName, this.urlFile);
    }
  }
  onPreview(fileName, url) {
    this.hasPreviewError = false;
    this.isPreviewDisplayed = false;
    this.isDocPreview = false;
    this.isImagePreview = false;
    this.blob = null;
    this.fileName = fileName;
    this.extension = fileName ? (fileName.split('.').length > 1 ? fileName.split('.').pop() : null) : null;
    if (this.extension) {
      const urlPreview = this.fromDocServer ? this.docServerService.getUrlPreview(url) : url;
      const mimeType = mime.getType(this.extension.toLowerCase());
      if (mimeType && mimeType.includes('image')) {
        this.isPreviewDisplayed = true;
        this.isImagePreview = true;
        this.url = urlPreview;
      } else if (this.getMediaType(this.fileName)) {
        this.mediaType = this.getMediaType(this.fileName);
        this.isPreviewDisplayed = true;
        this.isDocPreview = true;
        this.url = urlPreview as string;
      } else {
        this.fileName = null;
        this.url = null;
      }
      this.setUrl(this.url);
    } else {
      this.isPreviewDisplayed = true;
      this.showError = true;
    }
  }
  getMediaType(fileName: string) {
    const name = fileName.toLowerCase();
    const ext = name.split('.').pop();
    return mime.getType(ext);
  }
  // Duplicate du pipe docserverurl
  setUrl(src: any) {
    this.connectorService.getAccessToken().subscribe((token) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        Accept:
          'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
      });
      try {
        if (src && (`${src}`.includes('doc-server') || environment.config.backend.docserver.url.includes('localhost'))) {
          this.http
            .get(src, { headers, observe: 'response', responseType: 'blob' })
            .pipe(
              switchMap((response) => {
                return from(response.body.text()).pipe(
                  switchMap((text) => {
                    return of({ response: response, text: text });
                  })
                );
              })
            )
            .subscribe((finalResponse: { response: any; text: string }) => {
              this.hasPreviewError = false;
              if (finalResponse.text.includes('Could not find document:')) {
                this.hasPreviewError = true;
                this.blob = null;
              } else {
                const reader = new FileReader();
                reader.onloadend = (evt) => {
                  let base64 = evt.target.result as string;
                  const contentDisposition = finalResponse.response.headers.get('content-disposition');
                  let fileName;
                  let ext = 'png';
                  let mimeType = 'image/png';
                  try {
                    fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    ext = fileName.replaceAll('"', '').split('.').pop();
                  } catch {
                    fileName = null;
                  }
                  if (ext) {
                    ext = ext.toLocaleLowerCase();
                    mimeType = mime.getType(ext);
                  }
                  if (fileName && base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)) {
                    const mimeTypeToReplace = base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
                    base64 = base64.replace(mimeTypeToReplace, mimeType);
                    let url = null;
                    if (!mimeType.includes('image')) {
                      url = this.sanitized.bypassSecurityTrustResourceUrl(
                        `${base64}#toolbar=0&navpanes=0&scrollbar=0&zoom=80, 0, -20;`
                      );
                    } else {
                      url = this.sanitized.bypassSecurityTrustUrl(base64);
                    }
                    this.blob = url;
                  } else {
                    if (!mimeType.includes('image')) {
                      this.blob = null;
                    } else {
                      this.blob = this.sanitized.bypassSecurityTrustUrl('assets/imageError.webp');
                    }
                  }
                };
                reader.readAsDataURL(finalResponse.response.body as Blob);
              }
            });
        } else {
          this.blob = src;
        }
      } catch (err) {
        this.hasPreviewError = true;
        this.blob = null;
      }
    });
  }
}
