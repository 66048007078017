<div
  class="note-container"
  [ngClass]="{
    me: note?.author?.id === me.id ?? false
  }"
>
  <div class="note-picture">
    <iu-avatar-ui [avatarInfos]="note.author"></iu-avatar-ui>
  </div>
  <div class="note-content" data-cy="note-content">
    <div class="note-title">
      <h4 *ngIf="note?.author?.id !== me.id" class="note-author">
        {{ note?.author?.fullname ?? ('notes.unknownAuthor' | translate) }}
      </h4>
      <span class="note-date">
        {{ note?.createdAt }}
      </span>
    </div>
    <div class="note-text">
      <iu-input-mention-ui
        [content]="note?.content"
        [tagifyOptions]="{ pattern: null, enforceWhitelist: false, placeholder: null }"
        [type]="'text'"
      >
      </iu-input-mention-ui>
    </div>
    <!-- <div class="note-images"></div> -->
    <div class="note-attachements">
      <div *ngFor="let attachement of note?.attachements" class="note-attachement">
        <i class="{{ attachement?.filename | docIcon }}" aria-hidden="true" (click)="parent.downloadEntryAttachment(attachement)"></i>
        <span>{{ attachement?.filename | textLimit : 20 }}</span>
      </div>
    </div>
  </div>
</div>
