<div class="notes-container" #notesContainer>
  <h3 *ngIf="showTitle" class="notes-title">{{ 'detailsNote.title' | translate }}</h3>

  <div
    class="notes-wrapper"
    [ngClass]="{
      'has-scrollbar': notesWrapper?.scrollHeight > notesWrapper?.clientHeight
    }"
    #notesWrapper
  >
    <div *ngIf="!isNotesLoaded; else notesEmptyTemplate" class="loading-container centered-content">
      <p class="loading">{{ 'detailsNote.loading' | translate }}<span>.</span><span>.</span><span>.</span></p>
    </div>

    <ng-template #notesEmptyTemplate>
      <div
        *ngIf="notes.length === 0; else notesTemplate"
        class="notes-empty-container centered-content"
        data-cy="notes-panel-empty-container"
      >
        <i class="icon-empty-note icon-notes" aria-hidden="true"></i>
        <span>{{ 'detailsNote.emptyNote' | translate }}</span>
      </div>
    </ng-template>

    <ng-template #notesTemplate>
      <iu-note-ui *ngFor="let note of notes; let i = index" [note]="note" [parent]="this"></iu-note-ui>
    </ng-template>
  </div>
  <form class="notes-form" data-cy="notes-panel-form">
    <iu-input-mention-ui
      [readonly]="!isTagsLoaded || isNoteSending"
      [tags]="tags$"
      [sendIcon]="true"
      (enterEmitter)="sendNote()"
      #inputMentionRef
    >
    </iu-input-mention-ui>
    <div class="notes-buttons">
      <i
        *ngIf="entryAttachment === null"
        class="note-button icon-attach3x"
        aria-hidden="true"
        role="button"
        (click)="displayJoinFilesPanel = true"
        data-cy="notes-panel-file-button"
      ></i>
      <p *ngIf="entryAttachment !== null">
        {{ entryAttachment.name | textLimit : 20 }}
        <i
          class="note-button note-button-attachement icon-close"
          role="button"
          (click)="deleteEntryAttachment()"
          aria-hidden="true"
        ></i>
      </p>
      <i
        [ngClass]="{
          'note-button': true,
          'icon-bell': !isFollow,
          'icon-bell-slash': isFollow
        }"
        pTooltip="{{ 'detailsNote.' + (isFollow ? 'un' : '') + 'follow' | translate }}"
        (click)="follow(!isFollow)"
        aria-hidden="true"
        role="button"
        data-cy="notes-panel-follow-button"
      >
      </i>
    </div>
  </form>

  <ng-container *ngIf="displayJoinFilesPanel">
    <iu-modal-ui
      [title]="'joinFiles.title'"
      [hasCloseBtn]="true"
      [contentType]="ModalContentType.JOINFILE"
      (closeModalEmitter)="displayJoinFilesPanel = false"
    >
      <iu-join-file-ui
        [dsKey]="currentDataStoreKey"
        [adTable_ID]="table_id"
        [fromNotes]="true"
        (uploadEnd)="fileUpload($event); displayJoinFilesPanel = false"
      ></iu-join-file-ui>
    </iu-modal-ui>
  </ng-container>
</div>
