//TODO remove after 4.0.0 std
import { Injectable } from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridType,
  DataStore,
  DataStoreKey,
  DataStoreName,
  DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import { DocserverWsResponse } from '@compiere-ws/models/docserverWsResponse';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import {
  MongoSearchQuery,
  MongoSearchQueryCombination,
  MongoSearchQueryOperator,
  MongoSearchQueryOptions,
  MongoSearchQueryPart,
  MongoSearchQueryPartExp,
} from '@iupics-manager/models/mongo-search';
import { UserAccount } from '@iupics-manager/models/user-account';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { PoService } from '../po/po.service';

@Injectable()
export class DocServerService {
  private docServerUrl: string;
  private docServerFromWsUrl: string;
  private attachmentInteraction = true;

  constructor(
    private http: ApiService,
    private config: AppConfig,
    private poService: PoService,
    private store: DataStoreService,
    private connectorService: SecurityManagerService
  ) {
    this.docServerUrl = environment.config.backend.docserver.url;
    this.docServerFromWsUrl = this.config.getBackendResource('docserver');
    this.attachmentInteraction = this.config.getConstant('AttachmentInteraction');
  }
  // TODO execute this after 2.9.0 migration: db.documents.find().forEach(function(doc){Object.keys(doc).forEach(function(key){db.documents.update({ _id: doc._id },{ $rename: { [key]:key.toUpperCase() }})})});
  getNbDocuments(searchInformations: any[], combination: MongoSearchQueryCombination): Observable<number> {
    return this.http
      .post<any>(this.docServerUrl + '/getNbDocument', this.createQuery(searchInformations, combination, false, true))
      .pipe(
        map((response) => response),
        catchError((_) => of(0))
      );
  }

  uploadDocument(
    file: File,
    fileName: string,
    id?: string,
    type = 'DEFAULT_TYPE',
    additionalInformations?: any,
    isAttachment?: boolean
  ): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id);
    formData.append('type', type);
    formData.append('fileName', fileName);
    if (additionalInformations !== undefined) {
      Object.keys(additionalInformations).forEach((key) => formData.append(key, additionalInformations[key]));
    }
    const obs = this.http.post<string>(this.docServerUrl + '/upload', formData, { responseType: 'text' as 'json' });
    if (this.attachmentInteraction && isAttachment) {
      return obs.pipe(tap((docServerData) => {}));
    } else {
      return obs;
    }
  }
  searchDocuments(args?: any, attachmentInteraction = false): Observable<any> {
    if (args !== undefined) {
      const attachmentRequest: DataStoreRequest = {
        windowId: undefined,
        compiereRequest: {
          startRow: 0,
          endRow: -1,
          tableName: 'AD_Attachment',
          windowType: CompiereDataGridType.TABLE,
          filterModel: {
            AD_Table_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [args['META|TABLE_ID']],
              operators: [OperatorFilterType.EQUALS],
            },
            Record_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [args['META|RECORD_ID']],
              operators: [OperatorFilterType.EQUALS],
            },
          },
        },
      };

      return zip(
        attachmentInteraction && this.attachmentInteraction ? this.store.getDataGrid(attachmentRequest) : of(null),
        this.http.get<any>(this.docServerUrl + '/search' + this.buildQueryParams(args))
      ).pipe(
        map(([dataGridResponse, docServerResponse]) => {
          return docServerResponse.hits.map((attachment) => {
            if (dataGridResponse) {
              const findElem = dataGridResponse.data.find(
                (elem) => elem['Title'] === attachment['docId'] || elem['DocServerID'] === attachment['docId']
              );
              if (findElem) {
                attachment['attachment_ID'] = findElem['AD_Attachment_ID'];
              }
            }
            return attachment;
          });
        })
      );
    } else {
      return null;
    }
  }
  createQuery(
    searchInformations: any[],
    combination: MongoSearchQueryCombination,
    hasOrgSecurity: boolean = true,
    isAttachment = false
  ): MongoSearchQuery {
    const userContext = this.connectorService.getIupicsUserContext();
    const userAcount: UserAccount = this.connectorService.getIupicsUserAccount();
    const searchQuery = new MongoSearchQuery(userContext, userAcount.current_role.orgAccess);
    searchQuery.hasOrgSecurity = hasOrgSecurity;
    searchInformations.forEach((searchInformation) => {
      const queryPart = new MongoSearchQueryPart();
      Object.keys(searchInformation).forEach((key) => {
        if (searchInformation[key] !== undefined && searchInformation[key] !== null) {
          const operator: MongoSearchQueryOperator =
            searchInformation[key] === 'true' || searchInformation[key] === 'false'
              ? MongoSearchQueryOperator.EXIST
              : key.startsWith('META|ALL|') ||
                key.startsWith('META|OCR_DATA') ||
                key.startsWith('META|SUMMARY') ||
                key.startsWith('META|FILENAME')
              ? MongoSearchQueryOperator.REGEX
              : MongoSearchQueryOperator.EQUALS;
          queryPart.addExpression(new MongoSearchQueryPartExp(key, searchInformation[key], operator));
        }
      });
      const orgAccessString = [];
      searchQuery.orgAccess.forEach((orgId) => {
        orgAccessString.push(orgId + '');
      });
      if (isAttachment && searchInformation['META|TYPE'] !== 'ATTACHEMENT') {
        queryPart.addExpression(
          new MongoSearchQueryPartExp('META|AD_ORG_ID', orgAccessString, MongoSearchQueryOperator.IN)
        );
      }
      queryPart.expressions.forEach((exp, idx) => {
        if (idx > 0) {
          queryPart.addCombination(MongoSearchQueryCombination.AND);
        }
      });
      searchQuery.addQueryPart(queryPart);
      if (searchQuery.queryParts.length > 1) {
        searchQuery.combination = combination;
      }
    });
    return searchQuery;
  }
  advancedSearchDocuments(
    searchQuery: MongoSearchQuery,
    options: MongoSearchQueryOptions,
    tab_id?: number,
    table_id?: number,
    record_id?: number
  ): Observable<DocserverWsResponse> {
    if (searchQuery !== undefined) {
      let attachmentRequest: DataStoreRequest = null;
      if (table_id && record_id && options.attachmentInteraction) {
        attachmentRequest = {
          windowId: undefined,
          compiereRequest: {
            startRow: 0,
            endRow: -1,
            tableName: 'AD_Attachment',
            windowType: CompiereDataGridType.TABLE,
            filterModel: {
              AD_Table_ID: {
                filterType: CompiereDataGridFilterType.SET,
                values: [table_id],
                operators: [OperatorFilterType.EQUALS],
              },
              Record_ID: {
                filterType: CompiereDataGridFilterType.SET,
                values: [record_id],
                operators: [OperatorFilterType.EQUALS],
              },
            },
          },
        };
      }
      return zip(
        options.attachmentInteraction && this.attachmentInteraction && attachmentRequest
          ? this.store.getDataGrid(attachmentRequest)
          : of(null),
        this.http.post<any>(
          this.docServerFromWsUrl +
            this.buildQueryParams({ limit: options.limit, start: options.start, tabId: tab_id }),
          searchQuery
        )
      ).pipe(
        map(([dataGridResponse, docServerResponse]) => {
          return {
            entries: docServerResponse.documents.map((attachment) => {
              if (dataGridResponse) {
                const findElem = dataGridResponse.data.find(
                  (elem) => elem['Title'] === attachment['docId'] || elem['DocServerID'] === attachment['docId']
                );
                if (findElem) {
                  attachment['attachment_ID'] = findElem['AD_Attachment_ID'];
                }
              }
              return attachment;
            }),
            displayedTags: docServerResponse.displayedTags,
          };
        })
      );
    } else {
      return null;
    }
  }
  createfilterFromKey(dsKey: DataStoreKey) {
    const keyMap: Map<string, number> = this.store.extractRecordInfoFromDsKey(dsKey);
    if (keyMap.size > 0) {
      const filter = {};
      keyMap.forEach((value, key) => {
        filter['META|' + key.toUpperCase()] = value + '';
      });
      return filter;
    } else {
      return null;
    }
  }
  createDocFiltersInfo(docFilters: String, dsKey: DataStoreKey): any[] {
    const filters = [];
    const filterFromKey = this.createfilterFromKey(dsKey);
    const keyMap: Map<string, number> = this.store.extractRecordInfoFromDsKey(dsKey);
    if (docFilters) {
      const oldStore: DataStore = this.store.getStore(dsKey, DataStoreName.OLD) as DataStore;
      if (oldStore && oldStore.data) {
        const columns: string[] = docFilters.split(',');
        columns.forEach((col) => {
          let trimmedCol = col.trim();
          let restricted = true;
          if (trimmedCol !== '*' && trimmedCol.endsWith('*')) {
            trimmedCol = trimmedCol.substring(0, trimmedCol.length - 1);
            restricted = false;
          }
          let value = oldStore.data[trimmedCol];
          if (
            oldStore.data[trimmedCol] &&
            oldStore.data[trimmedCol].id !== null &&
            oldStore.data[trimmedCol].id !== undefined
          ) {
            value = oldStore.data[trimmedCol].id;
          }
          if (trimmedCol && trimmedCol.length > 0) {
            let filter = {};
            if (trimmedCol === '*') {
              if (columns.length === 1) {
                // if no others filters exist we can add this Global filter search
                if (keyMap.size > 1) {
                  filters.push(filterFromKey);
                } else {
                  // simple key
                  keyMap.forEach((v, k) => {
                    filter['META|ALL|' + k.toUpperCase()] = '.*' + v + '.*';
                  });

                  filters.push(filter);
                  filter = cloneDeep(filterFromKey);
                  filters.push(filter);
                }
              }
            } else {
              // linked table col
              if (value !== undefined && value !== null) {
                filter = restricted ? cloneDeep(filterFromKey) : {};
                filter['META|ALL|' + trimmedCol.toUpperCase()] = '.*' + value + '.*';
                filters.push(filter);
                filter = restricted ? cloneDeep(filterFromKey) : {};
                filter['META|' + trimmedCol.toUpperCase()] = value;
              } else {
                filter = restricted ? cloneDeep(filterFromKey) : {};
                filter['META|' + trimmedCol.toUpperCase()] = 'true';
              }
              if (restricted) {
                filter['META|TYPE'] = 'ATTACHEMENT';
              }
              filters.push(filter);
            }
          }
        });
      }
    }
    return filters;
  }
  createTaggedData(taggedColumns: String, dsKey: DataStoreKey): any {
    const filter = {};
    if (taggedColumns) {
      const oldStore: DataStore = this.store.getStore(dsKey, DataStoreName.OLD) as DataStore;
      if (oldStore && oldStore.data) {
        const columns: string[] = taggedColumns.split(',');
        columns.forEach((col) => {
          if (col) {
            if (Object.keys(oldStore.data).find((key) => key.toLowerCase() === col.toLowerCase())) {
              let id = oldStore.data[col];
              let displayValue: string = null;
              if (oldStore.data[col] && oldStore.data[col].id !== undefined && oldStore.data[col].id !== null) {
                id = oldStore.data[col].id;
                displayValue = oldStore.data[col].displayValue;
              }
              if (id !== null) {
                filter['META|' + col.toUpperCase()] = id;
                if (displayValue != null && displayValue.trim().length > 0) {
                  filter['META|' + col.toUpperCase() + '$'] = displayValue;
                }
              }
            }
          }
        });
      }
    }
    return filter;
  }
  downloadDocument(url: string) {
    return this.http.get(this.getUrlDownload(url), { responseType: 'blob' as 'json', observe: 'response' });
  }
  getUrlDownload(url: string) {
    return this.docServerUrl + url;
  }
  getUrlPreview(url: string) {
    return this.docServerUrl + url.replace('/download/', '/preview/');
  }

  deleteDocument(file: any, isAttachment?: boolean): Observable<any> {
    const obs = this.http.delete<any>(this.docServerUrl + '/document/' + file.docId, {
      responseType: 'text' as 'json',
    });
    if (this.attachmentInteraction && isAttachment && file.attachment_ID) {
      return obs.pipe(
        tap((docServerData) => {
          const s = this.poService.delete('AD_Attachment', file.attachment_ID).subscribe(() => s.unsubscribe());
        })
      );
    } else {
      return obs;
    }
  }

  private buildQueryParams(args: any): string {
    if (args !== undefined) {
      let query = '';
      Object.keys(args).forEach((key, index) => {
        if (args[key] !== undefined && args[key] !== null) {
          query +=
            (index === 0 ? '?' : '') +
            encodeURI(key) +
            '=' +
            args[key] +
            (index + 1 !== Object.keys(args).length ? '&' : '');
        }
      });
      return query;
    } else {
      return '';
    }
  }
}
