import { Injectable } from '@angular/core';
import { DocumentNotposted } from '@compiere-ws/models/document-notposted';
import { DocumentNotpostedService } from '@compiere-ws/services/document-notposted-service/document-notposted-service';
import { Observable } from 'rxjs';
import { DataStoreService } from '../data-store/data-store.service';

@Injectable()
export class DocumentNotpostedManagerService {
  constructor(private documentNotpostedService: DocumentNotpostedService) {}

  getDocumentNotPosted(): Observable<DocumentNotposted[]> {
    return this.documentNotpostedService.getDocumentNotPosted();
  }
}
